import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./landing-page/landing-page.module").then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: "request-quotation",
    loadChildren: () =>
      import("./request-quotation/request-quotation.module").then(
        (m) => m.RequestQuotationModule
      ),
  },
  {
    path: "sent-successfully",
    loadChildren: () =>
      import("./sent-successfully/sent-successfully.module").then(
        (m) => m.SentSuccessfullyModule
      ),
  },
  {
    path: "live-demo",
    loadChildren: () =>
      import("./live-demo/live-demo.module").then((m) => m.LiveDemoModule),
  },
  { path: 'privacyPolicy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    BrowserModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
