import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { ToastrService } from 'ngx-toastr';
import { environment } from "src/environments/environment";
// import { SimpleCrypt } from "ngx-simple-crypt";
@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  public token: any;
  constructor(
    // private toastr: ToastrService
    ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var lang = localStorage.getItem("lang");
    if (localStorage.getItem("User") == null) {
      let curl = environment.apiUrl || this.getHostName()
        req = req.clone({
          url: `${curl}/${req.url}`,
          setHeaders: {
         
          },
        });
    } else {
  
      // const token: string = localStorage.getItem('User')
      let curl = environment.apiUrl || this.getHostName()

        req = req.clone({
          url: `${curl}/${req.url}`,
          setHeaders: {
            // Authorization: `Bearer ` + token,
          },
        });
    }
    if (navigator.onLine) {
      localStorage.removeItem('noConnection')
    }else {
      localStorage.setItem('noConnection', 'true')
      throw { errors: "No internet connection, please try again!", code: 503 };
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
      if (navigator.onLine) {
        localStorage.removeItem('noConnection')
        if (error.status == 401) {
          location.reload()
          localStorage.clear();
          window.open("/login", "_Self");
          }
      }else {
        localStorage.setItem('noConnection', 'true')
        throw { errors: "No internet connection, please try again!", code: 503 };
      }
      
      return throwError(error);
      })
      );
  }

  getHostName(){

    return window.location.protocol + "//" + window.location.host
  }
}